var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c("addressBook", { on: { setAddress: _vm.setAddress } }),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-center mt-3" },
        [
          _c("mew-button", {
            attrs: {
              title: _vm.$t("ens.transfer"),
              "btn-size": "xlarge",
              disabled: _vm.isDisabled,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.transfer(_vm.resolvedAddr)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }